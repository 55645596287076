// extracted by mini-css-extract-plugin
export var arrow1Section3 = "DeFiRPC-content-module--arrow1Section3--b3e17";
export var arrow2Section3 = "DeFiRPC-content-module--arrow2Section3--5ecea";
export var button1Section1 = "DeFiRPC-content-module--button1Section1--e46ad";
export var button1Section5 = "DeFiRPC-content-module--button1Section5--c025f";
export var button2Section1 = "DeFiRPC-content-module--button2Section1--19385";
export var buttonText1Section1 = "DeFiRPC-content-module--buttonText1Section1--b8b9b";
export var buttonText1Section5 = "DeFiRPC-content-module--buttonText1Section5--e3e98";
export var buttonText2Section1 = "DeFiRPC-content-module--buttonText2Section1--6db43";
export var buttonsContainerSection1 = "DeFiRPC-content-module--buttonsContainerSection1--08bbe";
export var container1Section2 = "DeFiRPC-content-module--container1Section2--3eae8";
export var container1Section3 = "DeFiRPC-content-module--container1Section3--55ddf";
export var container1Section5 = "DeFiRPC-content-module--container1Section5--cea9e";
export var container2Section2 = "DeFiRPC-content-module--container2Section2--d1128";
export var container2Section3 = "DeFiRPC-content-module--container2Section3--73ea6";
export var container2Section5 = "DeFiRPC-content-module--container2Section5--d62e9";
export var container3Section2 = "DeFiRPC-content-module--container3Section2--a0ddf";
export var container3Section3 = "DeFiRPC-content-module--container3Section3--cb8d7";
export var container4Section2 = "DeFiRPC-content-module--container4Section2--39633";
export var container4Section3 = "DeFiRPC-content-module--container4Section3--b8c04";
export var containerSection1 = "DeFiRPC-content-module--containerSection1--ee4e1";
export var containerSection2 = "DeFiRPC-content-module--containerSection2--d1462";
export var containerSection3 = "DeFiRPC-content-module--containerSection3--cc466";
export var containerSection4 = "DeFiRPC-content-module--containerSection4--d80fc";
export var containerSection6 = "DeFiRPC-content-module--containerSection6--8a118";
export var containerbutton1Section5 = "DeFiRPC-content-module--containerbutton1Section5--2030c";
export var flexContainer = "DeFiRPC-content-module--flexContainer--dcb67";
export var greyDadingLineLeft = "DeFiRPC-content-module--greyDadingLineLeft--2a1b4";
export var greyDadingLineRight = "DeFiRPC-content-module--greyDadingLineRight--f4877";
export var iconSection6 = "DeFiRPC-content-module--iconSection6--b0dd5";
export var image1Section2 = "DeFiRPC-content-module--image1Section2--b5864";
export var image2Section2 = "DeFiRPC-content-module--image2Section2--9a8ba";
export var intersectionContainer = "DeFiRPC-content-module--intersectionContainer--b32c5";
export var intersectionNumber = "DeFiRPC-content-module--intersectionNumber--88dfc";
export var mainSection = "DeFiRPC-content-module--mainSection--6dd49";
export var mainStyleSection3 = "DeFiRPC-content-module--mainStyleSection3--b5677";
export var mainStyleSection5 = "DeFiRPC-content-module--mainStyleSection5--9d581";
export var rectangle2Section2 = "DeFiRPC-content-module--rectangle2Section2--59eba";
export var rectangle2image1Section2 = "DeFiRPC-content-module--rectangle2image1Section2--41fff";
export var rectangle2text1Section2 = "DeFiRPC-content-module--rectangle2text1Section2--d8664";
export var rectangle3Section2 = "DeFiRPC-content-module--rectangle3Section2--ab8a1";
export var rectangle3image1Section2 = "DeFiRPC-content-module--rectangle3image1Section2--8ae62";
export var rectangle3text1Section2 = "DeFiRPC-content-module--rectangle3text1Section2--ca32e";
export var rectangle4Section2 = "DeFiRPC-content-module--rectangle4Section2--98772";
export var rectangle4image1Section2 = "DeFiRPC-content-module--rectangle4image1Section2--d234e";
export var rectangle4text1Section2 = "DeFiRPC-content-module--rectangle4text1Section2--acd10";
export var rectangleSection2 = "DeFiRPC-content-module--rectangleSection2--b09f9";
export var rectangleSection5 = "DeFiRPC-content-module--rectangleSection5--f3c9f";
export var responsiveImageSection1 = "DeFiRPC-content-module--responsiveImageSection1--a9adc";
export var responsiveImageSection4 = "DeFiRPC-content-module--responsiveImageSection4--af516";
export var responsiveImageSection6 = "DeFiRPC-content-module--responsiveImageSection6--7821b";
export var section2 = "DeFiRPC-content-module--section2--bcbfd";
export var socialsSection6 = "DeFiRPC-content-module--socialsSection6--80e3d";
export var text1Section1 = "DeFiRPC-content-module--text1Section1--f0618";
export var text1Section2 = "DeFiRPC-content-module--text1Section2--138a9";
export var text1Section3 = "DeFiRPC-content-module--text1Section3--7da3e";
export var text1Section4 = "DeFiRPC-content-module--text1Section4--4b58e";
export var text1Section5 = "DeFiRPC-content-module--text1Section5--385be";
export var text1Section6 = "DeFiRPC-content-module--text1Section6--45104";
export var text2Section1 = "DeFiRPC-content-module--text2Section1--bb742";
export var text2Section2 = "DeFiRPC-content-module--text2Section2--d0ef3";
export var text2Section3 = "DeFiRPC-content-module--text2Section3--d2ec2";
export var text2Section4 = "DeFiRPC-content-module--text2Section4--fa127";
export var text2Section5 = "DeFiRPC-content-module--text2Section5--31336";
export var text2Section6 = "DeFiRPC-content-module--text2Section6--ae420";
export var text3Section1 = "DeFiRPC-content-module--text3Section1--eaa27";
export var text3Section2 = "DeFiRPC-content-module--text3Section2--270d1";
export var text3Section5 = "DeFiRPC-content-module--text3Section5--b983f";
export var text3Section6 = "DeFiRPC-content-module--text3Section6--87a1b";
export var text4Section2 = "DeFiRPC-content-module--text4Section2--e2067";
export var text4Section5 = "DeFiRPC-content-module--text4Section5--ddefc";
export var text5Section2 = "DeFiRPC-content-module--text5Section2--5b022";
export var text6Section2 = "DeFiRPC-content-module--text6Section2--edb2d";