import * as React from "react";
import MainLayout from "../layouts/MainLayout";
import DefiRpcContent from "../sections/DeFiRPC/DeFiRPC-content";

const DefiRpcPage = () => {
  return (
    <MainLayout pageTitle={"DeFi RPC"}>
     <DefiRpcContent></DefiRpcContent>
    </MainLayout>
  );
};

export default DefiRpcPage;
