import React from "react";
import * as Styles from "./DeFiRPC-content.module.scss";
import Button from "../../components/Button/Button";

import discordSvg from "../../../static/svg/discord.svg";
import telegramSvg from "../../../static/svg/telegram.svg";
import twitterSvg from "../../../static/svg/twitter-x.svg";

const DefiRpcContent = () => {
  const discordLink = "https://discord.com/invite/omniaprotocol";
  const telegramLink = "https://t.me/Omnia_protocol";
  const twitterLink = "https://twitter.com/omnia_protocol";

  return (
    <section className={Styles.mainSection}>
      <div id="section1" className={Styles.responsiveImageSection1}>
        <div className={Styles.containerSection1}>
          <div className={Styles.text1Section1}>The Fastest RPC provider</div>
          <div>
            <span
              className={Styles.text1Section1}
              style={{ paddingRight: "15px" }}
            >
              for
            </span>
            <span className={Styles.text2Section1}>DeFi Traders |</span>
          </div>
          <div className={Styles.text3Section1}>
            Cashback, Protection, Privacy - Optimize Your Experience
          </div>
          <div class={Styles.buttonsContainerSection1}>
            <Button
              type="link"
              link="https://app.omniatech.io/dashboard/generate-endpoints"
              theme="yellow"
              external
              cssButton={Styles.button1Section1}
            >
              <span className={Styles.buttonText1Section1}>Try for Free</span>
            </Button>
            <Button
              type="link"
              link="https://docs.omniatech.io/defi/rpc-defi-features"
              external
              cssButton={Styles.button2Section1}
            >
              <span className={Styles.buttonText2Section1}>
                Explore Features
              </span>
            </Button>
          </div>
        </div>
      </div>

      <div id="section2" className={Styles.section2}>
        <div className={Styles.containerSection2}>
          <div className={Styles.container1Section2}>
            <div className={Styles.rectangleSection2}>
              <span className={Styles.text1Section2}>CASHBACK</span>
              <span className={Styles.text2Section2}>Claim Your Rewards</span>
              <span className={Styles.text3Section2}>
                Maximize your MEV opportunities by routing transactions through
                our unique routing to private mempools. Don't leave any cash on
                the table.
              </span>
            </div>
            <div className={Styles.rectangleSection2}>
              <span className={Styles.text1Section2}>REINFORCED TX</span>
              <span className={Styles.text2Section2}>
                Never-Fail Transactions
              </span>
              <span className={Styles.text3Section2}>
                Eliminate 'transaction not found' errors forever. OMNIA ensures
                your transactions always execute.
              </span>
            </div>
            <div className={Styles.rectangleSection2}>
              <span className={Styles.text1Section2}>RPC</span>
              <span className={Styles.text2Section2}>
                Unleashed Performance
              </span>
              <span className={Styles.text3Section2}>
                Accelerate your transactions with OMNIA's advanced
                infrastructure. Experience unmatched speed and efficiency.
              </span>
            </div>
            <div className={Styles.rectangleSection2}>
              <span className={Styles.text1Section2}>PROTECTION</span>
              <span className={Styles.text2Section2}>
                Safeguard Your Trades
              </span>
              <span className={Styles.text3Section2}>
                From frontrunning to sandwich attacks, and deceptive honeypots,
                OMNIA has you covered. Transact with confidence.
              </span>
            </div>
          </div>
          <div className={Styles.text4Section2}>LAYER-1 BLOCKCHAINS</div>
          <div className={Styles.flexContainer}>
            <div className={Styles.rectangle2Section2}>
              <div className={Styles.rectangle2image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-1.png" alt="" />
              </div>
              <div className={Styles.rectangle2text1Section2}>
                Cashback, Frontrunning & Honeypot Protection
              </div>
            </div>
            <div class={Styles.container2Section2}>
              <div className={Styles.rectangle3Section2}>
                <div className={Styles.rectangle3image1Section2}>
                  <img  className={Styles.image2Section2} src="/images/defi-rpc/defi-icon-ethereum.png" alt="" />
                </div>
                <div className={Styles.rectangle3text1Section2}>Ethereum</div>
              </div>
              <div className={Styles.rectangle3Section2}>
                <div className={Styles.rectangle3image1Section2}>
                  <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-avalanche.png" alt="" />
                </div>
                <div className={Styles.rectangle3text1Section2}>Avalanche</div>
              </div>
              <div className={Styles.rectangle3Section2}>
                <div className={Styles.rectangle3image1Section2}>
                  <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-binance.png" alt="" />
                </div>
                <div className={Styles.rectangle3text1Section2}>
                  Binance Smart Chain
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.container3Section2}>
            <div className={Styles.greyDadingLineRight}></div>
            <div className={Styles.text6Section2}>COMING SOON</div>
            <div className={Styles.greyDadingLineLeft}></div>
          </div>
          <div className={Styles.container4Section2}>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-mina.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Mina</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-cosmos.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Cosmos</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-harmony.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Harmony</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-moonbeam.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Moonbeam</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-pulsechain.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>PulseChain</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-astar.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>
                Astar Network
              </div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-bitcoin.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Bitcoin</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-solana.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Solana</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-near.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Near</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-fantom.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Fantom</div>
            </div>
          </div>
          <div className={Styles.text4Section2}>LAYER-2 SOLUTIONS</div>
          <div className={Styles.text5Section2}>
            Rollups / Sidechains / EVM Compatible Chains
          </div>
          <div className={Styles.flexContainer}>
            <div className={Styles.rectangle2Section2}>
              <div className={Styles.rectangle2image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-1.png" alt="" />
              </div>
              <div className={Styles.rectangle2text1Section2}>
                Cashback, Frontrunning & Honeypot Protection
              </div>
            </div>
            <div className={Styles.container2Section2}>
              <div className={Styles.rectangle3Section2}>
                <div className={Styles.rectangle3image1Section2}>
                  <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-polygon.png" alt="" />
                </div>
                <div className={Styles.rectangle3text1Section2}>Polygon</div>
              </div>
              <div className={Styles.rectangle3Section2}>
                <div className={Styles.rectangle3image1Section2}>
                  <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-arbitrum.png" alt="" />
                </div>
                <div className={Styles.rectangle3text1Section2}>Arbitrum</div>
              </div>
              <div className={Styles.rectangle3Section2}>
                <div className={Styles.rectangle3image1Section2}>
                  <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-aurora.png" alt="" />
                </div>
                <div className={Styles.rectangle3text1Section2}>Aurora</div>
              </div>
            </div>
          </div>
          <div className={Styles.container3Section2}>
            <div className={Styles.greyDadingLineRight}></div>
            <div className={Styles.text6Section2}>COMING SOON</div>
            <div className={Styles.greyDadingLineLeft}></div>
          </div>
          <div className={Styles.container4Section2}>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-zkspace.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>ZKSpace</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-gnosis.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Gnosis</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-starknet.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>StarkNet</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-optimism.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>Optimism</div>
            </div>
            <div className={Styles.rectangle4Section2}>
              <div className={Styles.rectangle4image1Section2}>
                <img className={Styles.image1Section2} src="/images/defi-rpc/defi-icon-base.png" alt="" />
              </div>
              <div className={Styles.rectangle4text1Section2}>
                Base
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="section3" className={Styles.mainStyleSection3}>
        <div className={Styles.containerSection3}>
          <div className={Styles.text1Section3}>HOW IT WORKS</div>
          <div className={Styles.container1Section3}>
            <div className={Styles.container2Section3}>
              <div className={Styles.intersectionContainer}>
                <div className={Styles.arrow1Section3}></div>
                <div className={Styles.arrow2Section3}></div>
                <div className={Styles.intersectionNumber}>1</div>
              </div>
              <div className={Styles.text2Section3}>
                Connect Your Wallet & Choose Your Chain
              </div>
            </div>
            <div className={Styles.container3Section3}>
              <div className={Styles.intersectionContainer}>
                <div className={Styles.arrow1Section3}></div>
                <div className={Styles.arrow2Section3}></div>
                <div className={Styles.intersectionNumber}>2</div>
              </div>
              <div className={Styles.text2Section3}>
                Inject Endpoint and Get Protected
              </div>
            </div>
            <div className={Styles.container4Section3}>
              <div className={Styles.intersectionContainer}>
                <div className={Styles.arrow1Section3}></div>
                <div className={Styles.arrow2Section3}></div>
                <div className={Styles.intersectionNumber}>3</div>
              </div>
              <div className={Styles.text2Section3}>Get MEV Cashback</div>
            </div>
          </div>
        </div>
      </div>
      <div id="section4" className={Styles.responsiveImageSection4}>
        <div className={Styles.containerSection4}>
          <p className={Styles.text1Section4}>The OMNIA Advantage</p>
          <p className={Styles.text2Section4}>
            Ethereum, Polygon, Arbitrum, Every transaction, regardless of size,
            is at risk of front-running and sandwich attacks. Bots spot your
            transaction in the public mempool, outbid your gas fee, and leapfrog
            your trade, capitalizing on price changes. If your transaction isn't
            seen as profitable, it could be dropped, leading to "transaction not
            found" errors.
          </p>
          <p className={Styles.text1Section4}>Our Solution</p>
          <p className={Styles.text2Section4}>
            OMNIA routes your transactions to private mempools and auction
            houses. If deemed profitable, you share in the revenue as cashback.
            If not, we ensure your transaction still gets processed through our
            reinforced transaction broadcast to the private or public mempools,
            depending on user's choice. With OMNIA, you're covered either way.
          </p>
        </div>
      </div>
      <div id="section5" className={Styles.mainStyleSection5}>
        <div className={Styles.text1Section5}>PERFORMANCE THAT SPEAKS</div>
        <div className={Styles.container1Section5}>
          <div className={Styles.rectangleSection5}>
            <div className={Styles.text2Section5}>
              Uninterrupted Decentralized Service
            </div>
            <div className={Styles.container2Section5}>
              <div className={Styles.text3Section5}>100%</div>
              <div className={Styles.text4Section5}>UPTIME</div>
            </div>
          </div>
          <div className={Styles.rectangleSection5}>
            <div className={Styles.text2Section5}>High Volume Handling</div>
            <div className={Styles.container2Section5}>
              <div className={Styles.text3Section5}>200M</div>
              <div className={Styles.text4Section5}>RPC CALLS/DAY</div>
            </div>
          </div>
          <div className={Styles.rectangleSection5}>
            <div className={Styles.text2Section5}>Verified Transactions</div>
            <div className={Styles.container2Section5}>
              <div className={Styles.text3Section5}>60M</div>
              <div className={Styles.text4Section5}>DAILY</div>
            </div>
          </div>
          <div className={Styles.rectangleSection5}>
            <div className={Styles.text2Section5}>Frontrunning Protection</div>
            <div className={Styles.container2Section5}>
              <div className={Styles.text3Section5}>13M</div>
              <div className={Styles.text4Section5}>
                DAILY TRANSACTIONS SECURED
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.containerbutton1Section5}>
          <Button
            type="link"
            link="https://status.omniatech.io/"
            theme="yellow"
            external
            cssButton={Styles.button1Section5}
          >
            <span className={Styles.buttonText1Section5}>Check Our Status</span>
          </Button>
        </div>
      </div>
      <div id="section6" className={Styles.responsiveImageSection6}>
        <div className={Styles.containerSection6}>
          <p className={Styles.text1Section6}>
            Why DeFi Traders Choose Omnia's Decentralized RPC
          </p>
          <p className={Styles.text2Section6}>
            DeFi traders want efficient, reliable, and transparent trading. We
            optimize your transactions for MEV and ensure uninterrupted service
            as a decentralized RPC, and provide clear visibility into your
            transaction profits and distributions. With Omnia, you're set for a
            seamless and profitable DeFi trading experience.
          </p>
          <div className={Styles.socialsSection6}>
            <p className={Styles.text3Section6}>Chat With Us On:</p>
            <div>
              <a href={discordLink} target="_blank" rel="noreferrer">
                <img
                  className={Styles.iconSection6}
                  src={discordSvg}
                  alt="Discord"
                />
              </a>
              <a href={telegramLink} target="_blank" rel="noreferrer">
                <img
                  className={Styles.iconSection6}
                  src={telegramSvg}
                  alt="Telegram"
                />
              </a>
              <a href={twitterLink} target="_blank" rel="noreferrer">
                <img
                  className={Styles.iconSection6}
                  src={twitterSvg}
                  alt="Twitter"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DefiRpcContent;
